/* For Join And Host Views */

.join {
  padding: 0 1rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80vh;
  /* border: 2px solid red; */
}

.game-logo {
  width: 10rem;
  height: 10rem;
  align-self: center;
  object-fit: cover;
}

.intro-title,
.intro-content {
  text-align: center;
}

.intro {
  margin-bottom: 0.1rem;
}

.intro-title {
  font-family: hobo-std, sans-serif;
  font-size: 3rem;
  font-weight: 700;
  color: #ee704b;
  margin-bottom: 1.3rem;
  /* margin-top: -1rem; */
}

.intro-content {
  font-size: 1.35rem;
  color: #fff;
  margin-bottom: 0.8rem;
}
/*  */
.input-container {
  width: 400px;
  /* background: #fff; */
  border: 1px solid #ee704b;
  box-shadow: 0 2px 4px rgba(218, 129, 12, 0.1),
    0 8px 16px rgba(219, 92, 18, 0.1);
  border-radius: 8px;
  padding: 0.8rem 1rem;
  text-align: center;
  margin: auto;
}
.input-container h2 {
  color: #ee704b;
  margin-bottom: 1.7rem;
  text-decoration: underline;
}
.input-container h4 {
  color: #ee704b;
}
.inputs-group {
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
}
.inputs-group label {
  text-align: left;
  font-size: 1.2rem;
  color: #ee704b;
  /* margin-bottom: 0.35rem; */
}

.inputs-group input {
  font-size: 1.15rem;
  padding: 0.25rem 0.2rem;
  background: none;
  border: none;
  border-bottom: 2px solid #ee704b;
  color: #fff;
}

.inputs-group select {
  font-size: 1.15rem;
  padding: 0.25rem 0.2rem;
  background: none;
  border: none;
  border-bottom: 2px solid #ee704b;
  color: #fff;
}

.inputs-group select {
  font-size: 1.15rem;
  padding: 0.25rem 0.2rem;
  background: none;
  border: none;
  border-bottom: 2px solid #ee704b;
  /* color: #fff; */
}

.inputs-group option {
  font-size: 1.15rem;
  padding: 0.25rem 0.2rem;
  background: none;
  background-color: grey;
  border: none;
  border-bottom: 2px solid #ee704b;
  color: #fff;
}

.inputs-group select option[disabled][selected] {
  color: gray;
}

.inputs-btn {
  display: block;
  align-self: center;
  font-size: 1.25rem;
  background-color: #34b9c7;
  color: #f1f1f1;
  padding: 0.15rem 1rem;
  border: none;
  border-radius: 1.25rem;
  margin: auto;
}
.inputs-btn-danger {
  display: block;
  align-self: center;
  font-size: 1.25rem;
  background-color: #e9304f;
  color: #f1f1f1;
  padding: 0.15rem 1rem;
  border: none;
  border-radius: 1.25rem;
  margin: auto;
}
.inputs-btn-warning {
  /* display: block; */
  /* align-self: center; */
  font-size: 1.25rem;
  background-color: #efc520;
  color: #f1f1f1;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 1.25rem;
  /* margin: auto; */
}
#firstBtn {
  margin: 1.6rem auto 0;
}
.inputs-join {
  margin-bottom: 1.2rem;
}
.inputs-join input {
  padding: 0;
}
#room-code-input {
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 700px) {
  .join {
    padding: 5rem 1rem 1rem;
    min-height: 100vh;
  }
  .input-container {
    padding: auto 0.5rem;
    display: flex;
    flex-direction: column;
    /* border: 3px solid #ff4c29; */
  }

  .inputs-group + .inputs-group {
    margin-top: 2.5rem;
  }

  .margin-s {
    margin-bottom: 1rem;
  }

  .game-logo {
    width: 12rem;
    height: 12rem;
  }
}
@media screen and (max-width: 450px) {
  .input-container {
    width: 320px;
  }
}

/* .input-container {
  display: flex;
  justify-content: space-around;
}

.inputs-group {
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
}

.inputs-group label {
  font-size: 1.125rem;
  color: #ee704b;
  margin-bottom: 0.35rem;
}

.inputs-group input {
  font-size: 1.15rem;
  padding: 0.25rem 0.5rem;
  background: none;
  border: none;
  border-bottom: 2px solid #ee704b;
  color: #fff;
  border-radius: 0.5rem; 
}

.inputs-btn {
  display: block;
  align-self: center;
  font-size: 1.25rem;
  background-color: #34b9c7;
  color: #f1f1f1;
  padding: 0.25rem 1rem;
  border: none;
  border-radius: 1.25rem;
  margin: 1.67rem auto 0;
}
.inputs-btn a {
  color: white;
} */

/* For host setup screen */

.adj-container {
  color: #fff;
  padding: 1rem 2rem;
}

.adj-wrapper {
  /* background: limegreen; */
}

.box-title {
  text-align: center;
  margin-bottom: 2rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #eee;
}

.input-box {
  order: 2;
  /* background: blue; */
  display: flex;
  flex-direction: column;
}

.highlight-inputs {
  border: 2px solid #34b9c7;
  padding: 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
}

.room-code .highlight-inputs {
  margin-left: 2rem;
}

.inputs {
}

.inputs > div {
  margin: 1.5rem 0;
}

.inputs > div div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: red; */
}

.inputs > div div label {
  display: block;
  flex-basis: 55%;
}

.inputs > div div input {
  display: block;
  width: 15%;
  border: 2px solid #34b9c7;
  background: none;
  color: #fff;
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem;
}

.inputs > div > div > div {
  flex-basis: 15%;
  position: relative;
}

.inputs > div > div > div > input {
  color: #fff;
  width: 100%;
  font-size: 1.15rem;
  padding: 0.65rem 0.75rem;
}

.inc-btn,
.dec-btn {
  background: none;
  border: none;
  padding: none;
  color: #fff;
  position: absolute;
  right: 0;
  height: 40%;
  /* border: 2px solid #000; */
  display: inline-flex;
  justify-content: center;
}

.inc-btn {
  top: 15%;
}

.dec-btn {
  top: 53%;
}

.inputs > div div input::placeholder {
  color: #fff;
  opacity: 0.75;
}

.inputs > div div .add-word-input {
  flex-basis: 55%;
  border: none;
  border-bottom: 2px solid #34b9c7;
  border-radius: 0;
}

.input-box .inputs-btn {
  margin: 3rem auto 0;
}

.inputs > div > div > div.timer-input {
  flex-basis: 20%;
}

.word-btn {
  background: #34b9c7;
  color: #fff;
  border: none;
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem;
}

.adjectives {
  width: 40rem;
  align-self: center;
  padding: 0 1rem 1rem;
}

.adjectives .group-title {
  font-weight: 500;
  font-size: 2rem;
  color: #fff;
  margin-bottom: 2.5rem;
}
.adjectives .choose-words-desc {
  font-size: 1.6rem;
  /* font-weight: 500;
  margin-bottom: 2.5rem;
  color: #fff; */
}
.adjectives .choose-words-desc span {
  color: #34b9c7;
}

.adjectives .list-group {
  /* display: grid; */
  /* grid-template-columns: repeat(auto-fit, minmax(100%, 1.5fr)); */
  /* grid-auto-columns: minmax(8rem, 50%); */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.95rem;
  order: 1;
}

.adjectives .list-group .list-group-item {
  padding: 0.75rem 0.5rem;
  font-weight: 500;
  border-radius: 0.25rem;
  text-align: center;
  color: #fff;
  min-width: 8rem;
  background: none;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  border: 3px solid #ee704b;
}

.adjectives .list-group .list-group-item.host {
  pointer-events: none;
}

.players-group {
  width: 100%;
}

.players-group .list-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
}

.players-group .list-group .list-group-item {
  font-size: 1.25rem;
  padding: 0.75rem 0.5rem;
  border: 2px solid #000;
  position: relative;
}

.players-group .players-title {
  font-size: 2.5rem;
  color: #000;
  text-align: center;
  margin-bottom: 1rem;
}

.players-group .btn {
  display: block;
  margin: 1rem auto 0;
}

.choose-subject {
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.choose-subject .btn {
  margin: 0 0 0 1rem;
  display: inline-block;
  font-weight: 600;
  padding: 0.75rem;
  background: #ff4c29;
}

.subject {
  margin: 0;
  font-size: 1.5rem;
  color: #000;
}

.close-btn {
  /* width: 0.5rem;
  height: 0.5rem; */
  background: none;
  border: none;
  position: absolute;
  right: -0.7rem;
  top: -1rem;
  color: #000;
  /* border: 2px solid #000; */
  padding: 0rem 0.25rem;
  background: #ff4c29;
  border-radius: 50%;
}

@media screen and (max-width: 766px) {
  .adj-wrapper {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .adjectives {
    width: 100%;
  }
  .adjectives .group-title {
    text-align: center;
  }
  .adjectives .list-group {
    /* flex-direction: column; */
    justify-content: center;
    order: 2;
  }
  .input-box {
    order: 0;
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 766px) and (max-width: 950px) {
  .inputs > div > div > div {
    flex-basis: 20%;
  }
}

/* Players view */

.no-of-players {
  color: #fff;
  text-align: center;
  margin-bottom: 2.5rem;
}

.no-of-players p:nth-child(1) {
  font-size: 3rem;
  margin: 0;
}

.no-of-players p:nth-child(2) {
  font-size: 1.35rem;
  margin: 0;
  /* font-weight: 400; */
}

.list-title {
  font-size: 1.75rem;
  color: #fff;
  /* text-align: center; */
  padding: 0 5rem 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #aaa;
}

.category-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
}

.player-details ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.player,
.player-set,
.player-unset {
  font-size: 1.25rem;
  color: #fff;
  border: 2px solid #fff;
  padding: 0.25rem 2rem 0.25rem 3.1rem;
  position: relative;
  margin-top: 1rem;
}
.player,
.player-set,
.player-unset {
  margin: 1rem 0 0 3rem;
}

/* observer */
.player::before {
  content: "";
  width: 3rem;
  height: 3rem;
  background-color: #ff4c29;
  border-radius: 50%;
  position: absolute;
  top: -0.26rem;
  left: -0.9rem;
}
.category-list.subjects .player::before {
  content: "";
  width: 3rem;
  height: 3rem;
  background-color: #34b9c7;
  border-radius: 50%;
  position: absolute;
  top: -0.24rem;
  left: -0.9rem;
}
/* observer  */

/* host */
.player-set {
  cursor: not-allowed;
}

.player-unset {
  cursor: pointer;
}
.player-unset::before,
.player-set::before {
  content: "+";
  padding: 0.6rem 1.2rem;
  background: #ff4c29;
  border-radius: 50%;
  position: absolute;
  top: -0.4rem;
  left: -0.9rem;
}
.category-list.subjects .player-set::before,
.category-list.subjects .player-unset::before {
  background-color: #34b9c7;
  content: "-";
  padding: 0.6rem 1.38rem;
  border-radius: 50%;
  position: absolute;
  top: -0.4rem;
  left: -0.9rem;
}

/* host */

.player-details .inputs-btn {
  margin: 1rem auto;
}

@media screen and (max-width: 766px) {
  .player-details {
    padding: 1rem 0;
  }
  .player-details ul {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .player {
    margin: 1rem 0 0 0;
  }
  .player-details .category-list {
    margin: 2rem 0;
  }
}

/* Game Screen */

.game-screen {
  padding-bottom: 1rem;
}

.game-screen .game-content {
  display: flex;
  justify-content: space-around;
}

.game-screen .choose-title {
  text-align: center;
}

.game-screen .adjectives .group-title {
  margin-bottom: 1rem;
}

.game-screen .adjectives p {
  color: #fff;
}

.game-screen .list-group > div > .list-group-item.limit {
  cursor: not-allowed;
}

.game-screen .list-group > div > .list-group-item.nolimit {
  cursor: pointer;
}

.game-screen .list-group > div > .list-group-item.active {
  background: #ff694b;
  color: #fff;
  border: none;
}

.game-screen .word-choice-input {
  display: none;
}

.game-screen .inputs-btn {
  font-size: 1.475rem;
  padding: 0.5rem 2rem;
  border-radius: 1.75rem;
  margin: 0 auto;
}

.details {
  display: flex;
  justify-content: center;
}
.game-screen .game-details {
  width: 25rem;
}
.game-screen .game-details .info {
  padding: 2.25rem;
  border: 3px solid #34b9c7;
  border-radius: 50%;
}

.game-screen .game-details .timer {
  font-size: 2.25rem;
  font-weight: 600;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  padding: 2.25rem 3rem;
}

.game-screen .game-details .info p {
  color: #fff;
  text-align: center;
  margin: 0;
}

.game-screen .game-details .info p:nth-child(2) {
  font-size: 3.25rem;
  font-weight: 600;
}

.subject-details {
  margin-top: 2rem;
  justify-content: center;
  flex-direction: row;
}
.subject-details .player {
  margin-left: 1rem;
}
.subject-details p:nth-child(1) {
  font-size: 1.5rem;
  color: white;
  margin: 0.7rem 0.4rem 0 0;
}

.game-screen .inputs-btn.submitted {
  cursor: not-allowed;
  pointer-events: none;
  background: #ababab;
}

.observers-list {
  height: 15rem;
  list-style-type: none;
  color: #fff;
  margin: 2.5rem 0 0 0;
  padding: 0 1rem;
  overflow-y: scroll;
}

.observers-list-title {
  color: #fff;
  font-size: 1.25rem;
  text-align: center;
}

.observers-list::-webkit-scrollbar {
  background: none;
  width: 0.35rem;
  margin-left: 0.5rem;
  border-radius: 0.75rem;
}

/* .observers-list::-webkit-scrollbar-track {
  border: 3px solid #ff4c29;
} */

.observers-list::-webkit-scrollbar-thumb {
  /* width: 100%; */
  background: yellow;
  border-radius: 0.75rem;
}

.observers-list .observer {
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 3px solid #ff4c29;
  margin-bottom: 1rem;
}

.observers-list .observer p {
  margin: 0;
}

.observers-list .observer span {
  display: block;
  width: 1rem;
  height: 1rem;
  background: #ee704b;
  border-radius: 50%;
}

.observers-list .observer.yes {
  border: 3px solid #34b9c7;
}

.observers-list .observer.yes span {
  background: #34b9c7;
}

@media screen and (max-width: 766px) {
  .game-screen {
    padding-bottom: 5rem;
  }
  .game-screen .game-content {
    flex-direction: column;
    justify-content: center;
  }
  .game-screen .game-details {
    width: 100%;
  }
  /* .details {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: rgba(51, 51, 51, 0.5);
  } */

  .game-screen .game-details .info:nth-child(1) {
    padding: 1.25rem 0;
    border: 3px solid #34b9c7;
    border-radius: 1rem;
  }

  .game-screen .game-details .timer {
    background: #333;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1;
    flex-basis: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
  }

  /* .game-screen .game-details .time p {
    color: #fff;
    text-align: center;
    margin: 0;
  } */

  .game-screen .game-details .players-left {
    margin: 0 auto;
  }

  /* .game-screen .game-details .players-left p:nth-child(2) {
    font-size: 1.65rem;
    font-weight: 600;
  } */
}

@media screen and (max-width: 753px) {
  .choose-words-desc {
    text-align: center;
  }
}
/* Table */

.table-wrapper {
  margin-bottom: 2rem;
}

.table-wrapper .intro-title {
  font-size: 2rem;
  margin: 0 0 1.5rem;
}

.table {
  list-style-type: none;
  padding: 0 3rem;
  display: grid;
  grid-template-columns: 0.1fr repeat(2, 1fr);
  grid-template-rows: 0.1fr repeat(2, 1fr);
  gap: 0.3em;
}

.table .category-title {
  font-size: 1.25rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  margin: 0;
  background: #ee704b;
  padding: 0.25rem 1rem;
  display: inline-block;
}

.table .table-desc {
  font-size: 1.5rem;
  text-align: center;
  color: #fff;
  background: #34b9c7;
  border: 2px solid #000;
}

.table .table-desc.inverted {
  writing-mode: vertical-lr;
  justify-self: end;
  transform: rotate(180deg);
}

.table .table-desc:nth-of-type(1) {
  grid-column: 2/3;
}

.table .table-item {
  border: 2px solid #000;
  padding: 0 0 1rem;
  background: #fff;
}

.table .table-item .word-block {
  display: flex;
  /* flex-flow: row; */
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 90%;
}

.table .table-item div p:nth-child(1) {
  padding: 0;
  margin: 0;
}

.table-wrapper .inputs-btn {
  margin: 0 auto;
}

.side-content {
  font-size: 1.75rem;
  font-weight: 600;
  color: #fff;
  text-align: center;
  margin-top: 2rem;
}

.word-wrapper {
  position: relative;
  /* border: 2px solid #000; */
}

.word-wrapper .count-votes {
  padding: 0.25rem 0.75rem;
  background: #000;
  color: #fff;
  position: absolute;
  bottom: -2rem;
  opacity: 0;
  transition: opacity 0.15s;
}

.word-wrapper:hover .count-votes {
  opacity: 1;
}

/* @media screen and (max-width: 766px) {
  .table {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(8, 1fr);
  }

  .table .category-title {
    font-size: 1.25rem;
    font-weight: 700;
    color: #fff;
    text-align: center;
    margin: 0;
    background: #ee704b;
    padding: 0.25rem 1rem;
    display: inline-block;
  }

  .table .table-desc {
    font-size: 1.5rem;
    text-align: center;
    color: #fff;
    background: #34b9c7;
    border: 2px solid #000;
  }

  .table .table-desc.inverted {
    writing-mode: sideways-lr;
    justify-self: center;
    transform: rotate(0deg);
  }

  .table .table-desc:nth-of-type(1) {
    grid-column: 2/3;
  }
} */
