.header {
  padding: 1rem 1rem 0rem;
  display: flex;
  justify-content: space-between;
  /* border: 3px solid #ee704b; */
}


.inputs-btn-header {
  font-size: 1.25rem;
  background-color: #34b9c7;
  color: #f1f1f1;
  padding: 0.15rem 1rem;
  max-height: 40px;
  border-radius: 1.25rem;
}

.inputs-btn-header-secondary {
  font-size: 1.25rem;
  background-color: #EE704B;
  color: #f1f1f1;
  padding: 0.15rem 1rem;
  max-height: 40px;
  border-radius: 1.25rem;
}

.logo {
  cursor: pointer;
}